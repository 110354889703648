import { Intervention, PictureScan } from "@ca/report";
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import FormControl from "@mui/material/FormControl/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import Typography from "@mui/material/Typography/Typography";
import { ReportContext } from "providers/Report";
import React, { useContext, useState } from "react";
import { ReactComponent as TextIcon } from "../../../../../assets/icons/icon-image-text.svg";
import RetributionButton from "../../RetributionButton";

export interface PictureTextDetectedInterventionProps {
  pictureScan: PictureScan;

  intervention: Intervention.pictureUsefulness | null;

  onUpdateIntervention: (intervention: Intervention.pictureUsefulness) => void;
}

const PictureTextDetectedIntervention: React.FC<
  PictureTextDetectedInterventionProps
> = ({ pictureScan, intervention, onUpdateIntervention }) => {
  const isInformative = intervention?.usefulness?.type === "informative";

  const [canTextBeReplacedByStyledText, setCanTextBeReplacedByStyledText] = useState<
    boolean | null
  >(
    intervention?.usefulness?.type === "informative"
      ? intervention.usefulness.canTextBeReplacedByStyledText
      : null
  );

  const { findErrorByPathAndType } = useContext(ReportContext)!;
  const error = findErrorByPathAndType(
    pictureScan.xPath,
    "textPictureShouldBeReplaceByStyledText"
  );

  const type = isInformative && error ? "error" : "info";

  const handleTextCanBeReplacedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCanTextBeReplacedByStyledText(
      (event.target as HTMLInputElement).value === "yes"
    );
  };

  const handleSubmit = () => {
    if (!intervention || intervention.usefulness.type !== "informative") return;
    if (canTextBeReplacedByStyledText === null) return;

    const payload = {
      ...intervention,
      usefulness: {
        ...intervention.usefulness,
        canTextBeReplacedByStyledText,
      },
    };

    onUpdateIntervention(payload);
  };

  let radioCanBeReplacedByStyledText = null;
  if (canTextBeReplacedByStyledText) {
    radioCanBeReplacedByStyledText = "yes";
  } else if (canTextBeReplacedByStyledText === false) {
    radioCanBeReplacedByStyledText = "no";
  }

  const title = !isInformative
    ? "Du text à été déctecté sur cette image mais étant decorative elle n'est pas concerné par ce critère"
    : error
    ? "Du text à été déctecté sur cette image, il doit être remplacé par du texte stylisé"
    : "Du text à été déctecté sur cette image, mais il ne peut pas être remplacé par du texte stylisé";

  return (
    <RetributionButton
      retrivalId={`textPicture-${pictureScan.xPath}`}
      type={type}
      Icon={(props) => <TextIcon {...props} fill="white" />}
      xPath={pictureScan.xPath}
      tooltipProps={{
        title,
        content: isInformative ? (
          <FormControl component="fieldset" sx={{ width: "100%" }}>
            <Box sx={{ mt: 1 }}>
              <Typography>
                Du text à été déctecté sur l'image, peut-il être remplacé par du
                texte stylisé?
              </Typography>
              <RadioGroup
                value={radioCanBeReplacedByStyledText}
                onChange={handleTextCanBeReplacedChange}
              >
                <FormControlLabel
                  value={"yes"}
                  sx={{
                    marginBottom: "-12px",
                    marginTop: "-2px",
                  }}
                  control={<Radio size="small" color={type} />}
                  label="Oui"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio size="small" color={type} />}
                  label="Non"
                />
              </RadioGroup>
            </Box>
            <Button
              variant="contained"
              sx={{
                mt: 2,
                borderRadius: "32px",
              }}
              color={type}
              onClick={handleSubmit}
            >
              Valider
            </Button>
          </FormControl>
        ) : (
          <></>
        ),
        referenceLink:
          "https://accessibilite.numerique.gouv.fr/methode/criteres-et-tests/#1.8",
      }}
    />
  );
};

export default PictureTextDetectedIntervention;
