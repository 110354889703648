import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  DbResultOkOne,
  InsertTables,
  supabase,
  UpdateTables,
} from "../supabaseClient";
import { logError } from "../utils/SentryUtils";
import useAuth from "../hooks/useAuth";

const fetchOrganizationsQuery = (userId: string) =>
  supabase
    .from("organizations")
    .select(
      `
    *,
    members:users_organizations(user_id),
    self:users_organizations!inner(user_id),
    projects(
      id, 
      http_scheme, 
      name, 
      domain,
      pages:project_pages(*)
    )
  `
    )
    .filter("self.user_id", "eq", userId);

export type Organization = DbResultOkOne<
  ReturnType<typeof fetchOrganizationsQuery>
>;

export interface NewOrganizationPayload {
  organization: Omit<InsertTables<"organizations">, "owner_id" | "id">;
  projectPayload: {
    name: string;
    url: URL;
  };
}

type OrganizationsContextProps = {
  organizations: Organization[];
  updateProjectPage: (payload: UpdateTables<"project_pages">) => Promise<void>;
  insertProjectPage: (payload: InsertTables<"project_pages">) => Promise<void>;
  fetchOrganizations: () => Promise<void>;
};

const OrganizationsContext = createContext<OrganizationsContextProps | null>(
  null
);

const OrganizationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { userId } = useAuth();

  const fetchOrganizations = useCallback(async () => {
    const { data, error } = await fetchOrganizationsQuery(userId);

    if (data) setOrganizations(data);
    if (error) logError("Error while fetching organizations", error);
  }, [userId]);

  const updateProjectPage = useCallback(async (payload: UpdateTables<"project_pages">) => {
    const { error } = await supabase.from("project_pages").update(payload).eq("id", payload.id!).eq("project_id", payload.project_id!);
    if (error) logError("Error while updating project page", error);

    fetchOrganizations();
  }, [fetchOrganizations]);

  const insertProjectPage = useCallback(async (payload: InsertTables<"project_pages">) => {
    const { error } = await supabase.from("project_pages").insert(payload);
    if (error) logError("Error while inserting project page", error);

    fetchOrganizations();
  }, [fetchOrganizations]);

  useEffect(() => {
    fetchOrganizations().finally(() => setIsLoading(false));
  }, [fetchOrganizations]);

  if (isLoading) return <div>Loading organization...</div>;

  return (
    <OrganizationsContext.Provider
      value={{
        organizations,
        fetchOrganizations,
        updateProjectPage,
        insertProjectPage,
      }}
    >
      {children}
    </OrganizationsContext.Provider>
  );
};

export { OrganizationsContext, OrganizationProvider };
