import AccordionSummary from "@mui/material/AccordionSummary/AccordionSummary";
import Box from "@mui/material/Box/Box";
import React, { useState } from "react";
import Typography from "@mui/material/Typography/Typography";
import Chip from "@mui/material/Chip/Chip";
import AccordionDetails from "@mui/material/AccordionDetails/AccordionDetails";
import Accordion from "@mui/material/Accordion/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";

export interface CriteriaAccordionProps {
  title: string;
  Icon: React.ReactNode;
  type: "info" | "warning" | "error" | "success";
  badgeValue?: number;
  children?: React.ReactNode;
  defaultIsExpanded?: boolean;
  expanded?: boolean;
  onExpandedChange?: (expanded: boolean) => void;
}

const CriteriaAccordion: React.FC<CriteriaAccordionProps> = ({
  title,
  Icon,
  type,
  defaultIsExpanded,
  expanded,
  badgeValue,
	children,
  onExpandedChange,
}) => {
  const { palette } = useTheme();
  const [isExpanded, setIsExpanded] = useState<boolean>(
    defaultIsExpanded ?? false
  );

  const handleExpandedChange = (_: React.SyntheticEvent) => {
    setIsExpanded(!isExpanded);
    onExpandedChange?.(!isExpanded);
  };

  return (
    <Accordion
      disableGutters={true}
      onClick={(e) => e.stopPropagation()}
      onChange={handleExpandedChange}
      expanded={expanded !== undefined ? expanded : isExpanded}
      sx={{
        "&.Mui-disabled": {
          backgroundColor: palette.common.white,
        },
      }}
    >
      <AccordionSummary 
				expandIcon={children && <ExpandMoreIcon />}
			>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            flexGrow: "1",
          }}
        >
          {Icon}
          <Typography>{title}</Typography>
          {badgeValue && (
            <Chip
              sx={{ marginLeft: "auto", marginRight: "16px" }}
              label={badgeValue}
              color={type}
            />
          )}
        </Box>
      </AccordionSummary>
			{children && <AccordionDetails>{children}</AccordionDetails>}
    </Accordion>
  );
};

export default CriteriaAccordion;
