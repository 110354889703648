import { Organization } from "providers/OrganizationsProvider";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import { createProjectRoute } from "router";

interface ProjectCardProps {
  project: Organization["projects"][number];
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
  return (
    <Link to={createProjectRoute(project.id)} className="border hover:bg-gray-100 transition border-gray-300 shadow-xl rounded-3xl p-5 w-1/4" key={project.id}>
      <div className="flex flex-row justify-center items-center">
        <h3 className="text-lg font-bold flex flex-grow line-clamp-1">{project.name}</h3>
        <ArrowForwardIos/>
      </div>
      <p className="font-normal mt-2 truncate">URL:&nbsp; {project.domain}</p>
    </Link>
  );
};

export default ProjectCard;
