import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <h1 className="text-6xl font-bold text-foreground mb-4">404</h1>
      <h2 className="text-2xl text-foreground mb-4">Oops! Page not found.</h2>
      <p className="text-foreground mb-8">The page you're looking for doesn't exist.</p>

      <Link 
        to="/" 
        className="cursor-pointer font-bold my-2 border border-black rounded-lg p-1.5 bg-green-950 
            text-white hover:bg-green-200 hover:text-black transition flex flex-row">
        Go Back Home
      </Link>
    </div>
  );
};

export default NotFoundPage;
