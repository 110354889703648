import { Box, Button } from '@mui/material';

enum SettingSection {
  Account = 'Mon compte',
  Subscription = 'Abonnement',
  Security = 'Sécurité',
}

interface SidebarProps {
  onSelect: (section: SettingSection) => void;
  activeSection: SettingSection;
}

const SettingSidebar: React.FC<SidebarProps> = ({ onSelect, activeSection }) => {
  return (
    <Box
      sx={{
        width: 200,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        p: 2,
        bgcolor: 'background.paper',
        borderRight: 1,
        marginTop: 3,
      }}
    >
      {Object.values(SettingSection).map((section) => (
        <Button
          key={section}
          variant={activeSection === section ? 'contained' : 'text'}
          onClick={() => onSelect(section as SettingSection)}
        >
          {section}
        </Button>
      ))}
    </Box>
  );
};

export { SettingSidebar, SettingSection };
