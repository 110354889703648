import Input from "@mui/material/Input/Input";
import Typography from "@mui/material/Typography/Typography";
import { StepsModal } from "components/modals/StepsModal";
import { NewProjectPayload } from "hooks/useCreateOrganization";
import useTechDetection, {
  NOT_FOUND_ERROR_MESSAGE,
} from "hooks/useTechDetection";
import { useMemo, useState } from "react";

interface ProjectStepProps {
  handleProjectUrlChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  projectName: string;
  setProjectName: (name: string) => void;
}

const ProjectStepComponent: React.FC<ProjectStepProps> = ({
  handleProjectUrlChange,
  projectName,
  setProjectName,
}) => {
  return (
    <Typography className="flex flex-col">
      <label htmlFor="projectName">
        Quel est le nom de votre site internet?
      </label>
      <Input
        id="projectName"
        name="projectName"
        value={projectName}
        onChange={(e) => setProjectName(e.target.value)}
      />

      <label className="mt-4" htmlFor="projectUrl">
        Lien du site
      </label>
      <Input
        id="projectUrl"
        name="projectUrl"
        placeholder="https://example.com"
        onChange={handleProjectUrlChange}
      />
    </Typography>
  );
};

interface UseProjectStepProps {
  handleSubmit: (
    payload: NewProjectPayload
  ) => Promise<null | StepsModal.StepError>;
}

interface UseProjectStepResult {
  step: StepsModal.Step;
  detectedCms: string | null;
}

const useProjectStep = ({
  handleSubmit,
}: UseProjectStepProps): UseProjectStepResult => {
  const { cms: detectedCms, detectUrl } = useTechDetection();

  const [projectName, setProjectName] = useState("");
  const [projectUrl, setProjectUrl] = useState<URL | null>(null);

  const handleProjectUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setProjectUrl(new URL(e.target.value));
    } catch {
      setProjectUrl(null);
    }
  };

  const step = useMemo(
    () => ({
      label: "Projet",
      validateStep: async () => {
        if (projectName.trim().length === 0) {
          return "Le nom du projet est requis";
        }

        if (projectUrl === null) {
          return "Le lien du projet est requis et doit être un lien valide";
        }

        return null;
      },
      StepComponent: (
        <ProjectStepComponent
          handleProjectUrlChange={handleProjectUrlChange}
          projectName={projectName}
          setProjectName={setProjectName}
        />
      ),
      handleNext: async () => {
        return detectUrl(projectUrl!).then((isDetected) => {
          if (!isDetected) {
            return NOT_FOUND_ERROR_MESSAGE;
          }

          return handleSubmit({
            name: projectName,
            url: projectUrl!,
          });
        });
      },
    }),
    [detectUrl, handleSubmit, projectName, projectUrl]
  );

  return {
    step,
    detectedCms,
  };
};

export default useProjectStep;
