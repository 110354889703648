import { Typography, Box } from '@mui/material';

const Subscription: React.FC = () => {
  return (
    <Box>
      <Typography variant="h5">Abonnement</Typography>
      <Typography variant="body1">Détails de l'abonnement...</Typography>
    </Box>
  );
};

export default Subscription;
