import { useState } from "react";
import Box from "@mui/material/Box/Box";
import Stepper from "@mui/material/Stepper/Stepper";
import StepLabel from "@mui/material/StepLabel/StepLabel";
import Step from "@mui/material/Step/Step";
import Typography from "@mui/material/Typography/Typography";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import Button from "@mui/material/Button/Button";

interface StepsModalProps {
  steps: StepsModal.Step[];
  onClose?: () => void;
}

const StepsModalForm: React.FC<StepsModalProps> = ({ steps, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);

  const [errorMessage, setErrorMessage] = useState<StepsModal.StepError | null>(
    null
  );

  const currentStep = steps[activeStep];
  const progress = ((activeStep + 1) / steps.length) * 100;
  const isFirstStep = activeStep === 0;
  const isLastStep = activeStep === steps.length - 1;
  const canGoBack = currentStep.canGoBack ?? true;

  const handleNext = async () => {
    const activeStepValidationError = await currentStep.validateStep?.();
    if (activeStepValidationError) {
      setErrorMessage(activeStepValidationError);
      return;
    }

    const nextStepValidationError = await currentStep.handleNext?.();
    if (nextStepValidationError) {
      setErrorMessage(nextStepValidationError);
      return;
    }

    if (isLastStep) {
      onClose?.();
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setErrorMessage(null);
  };

  const handleBack = () => {
    if (isFirstStep || !canGoBack) return;
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const CurrentStepComponent = currentStep.StepComponent;

  return (
    <Box className="mx-6 flex flex-col absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[700px] h-[400px] bg-white border-2 rounded-3xl shadow-lg p-4">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => (
          <Step
            key={index}
            sx={{
              "& .MuiStepLabel-label": {
                marginTop: "4px",
              },
            }}
          >
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box className="m-6 h-full flex flex-grow flex-col">
        {errorMessage && (
          <Typography sx={{ marginBottom: "8px" }} className="text-red-500">
            <strong>{errorMessage}</strong>
          </Typography>
        )}
        {CurrentStepComponent}
      </Box>

      <LinearProgress
        className="mx-6"
        variant="determinate"
        value={progress}
        sx={{ my: 2 }}
      />

      <Box
        className="flex justify-center items-end mx-6"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          disabled={isFirstStep || !canGoBack}
          onClick={handleBack}
          sx={{ mt: 1, mr: 1 }}
        >
          Précédent
        </Button>

        <Button onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
          {isLastStep ? "Terminer" : "Suivant"}
        </Button>
      </Box>
    </Box>
  );
};

export namespace StepsModal {
  export interface Step {
    label: string;
    validateStep?: () => Promise<StepError | null>;
    handleNext?: () => Promise<StepError | null>;
    canGoBack?: boolean;
    StepComponent: React.ReactElement;
  }

  export type StepError = string;

  export const Form = StepsModalForm;
}
