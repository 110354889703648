import { useEffect, useMemo } from "react";
import useOrganizations from "./useOrganizations";
import { useNavigate } from "react-router-dom";
import { ORGANIZATIONS_URL } from "../router";
import { Organization } from "../providers/OrganizationsProvider";

// This is to avoid passing `Project | null` to the rest of the app when waiting for redirect
const FAILOVER_PROJECT: Project = { 
  id: "", 
  name: "",
  http_scheme: "http", 
  domain: "",
  pages: []
}

export type Project = Organization["projects"][0];

const useProject = (projectId: string): Project => {
  const navigate = useNavigate();
  const organizations = useOrganizations();

  const project = useMemo(
    () =>
      organizations
        .flatMap((organization) => organization.projects)
        .find((project) => project.id === projectId),
    [organizations, projectId]
  );

  useEffect(() => {
    if (!project) {
      navigate(ORGANIZATIONS_URL);
    }
  }, [project, navigate]);

  return project || FAILOVER_PROJECT;
};

export default useProject;