import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import useScaner from "hooks/useScaner";
import React, { useContext } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { Typography } from "@mui/material";
import { CheckAccessFrameContext } from "providers/CheckAccessFrame/CheckAccessFrame";
import Alert from "@mui/material/Alert/Alert";

export interface SummarySidebarDetailsProps {}

const SummarySidebarDetails: React.FC<SummarySidebarDetailsProps> = () => {
  const { currentProjectPage } = useContext(CheckAccessFrameContext)!;
  const { startScan, isScaning, currentPageScan } = useScaner();

  return (
    <Box className="w-full flex justify-center flex-col mt-6">
      <Box className="w-full flex justify-center">
        <Button
          onClick={() => startScan([currentProjectPage.id])}
          variant="contained"
          endIcon={<CachedIcon />}
          color="primary"
          sx={{
            border: "1px solid white",
            height: "40px",
            width: "auto",
            minWidth: "auto",
          }}
          size="large"
          disabled={isScaning}
        >
          <Typography variant="h5">
            {isScaning ? "Scanning..." : "Scan"}
          </Typography>
        </Button>
      </Box>
      {!currentPageScan && (
        <Box sx={{ marginX: 4, marginTop: 5 }}>
          <Alert
            severity="warning"
            variant="filled"
            sx={{ width: "100%", display: "flex", alignItems: "center" }}
          >
            Aucun scan n'a été effectué sur cette page, veuillez lancer un scan
            pour l'ajouter au rapport
            <Button
              onClick={() => startScan([currentProjectPage.id])}
              variant="contained"
              disabled={isScaning}
              color="secondary"
              size="small"
              sx={{ marginLeft: "10px" }}
            >
              {isScaning ? "Scanning..." : "Commencer le scan"}
            </Button>
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default SummarySidebarDetails;
