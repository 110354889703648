import { useTheme } from "@mui/material";
import Box from "@mui/material/Box/Box";
import React, { useContext, useMemo } from "react";
import { ReportContext, TargetedNonCompliantErrors } from "providers/Report";
import { UnionTypeArray } from "utils/tsUtils";
import {
  ImageCriteria,
  InterventionRequirement,
  number_to_image_criteria,
} from "@ca/report";
import { ReactComponent as ImageIcon } from "../../../../assets/icons/icon-image.svg";
import { ReactComponent as DecorativeImage } from "../../../../assets/icons/icon-decorative-image.svg";
import { ReactComponent as InformativeImage } from "../../../../assets/icons/icon-informative-image.svg";
import { ReactComponent as TextIcon } from "../../../../assets/icons/icon-image-text.svg";
import {
  AllPicturesNeededInterventionTypes,
  ALL_PICTURES_NEEDED_INTERVENTION_TYPES,
} from "definitions/topic_details";
import SidebarTopicHeader from "./SidebarTopicHeader";
import CriteriaStateAccordion from "../accordion/CriteriaStateAccordion";
import AccordionPictoLink from "../accordion/AccordionPictoLink";

export interface PicturesSidebarTopicDetailsProps {}

const PicturesSidebarTopicDetails: React.FC<
  PicturesSidebarTopicDetailsProps
> = () => {
  const { palette } = useTheme();
  const {
    pageReport,
    nonCompliantCriterias,
    needInterventionsCriterias,
  } = useContext(ReportContext)!;

  const picturesErrors: Record<ImageCriteria, TargetedNonCompliantErrors[]> =
    useMemo(() => {
      return Object.fromEntries(
        nonCompliantCriterias
          .filter((criteria) => criteria.topic === "images")
          .map((criteria) => [
            number_to_image_criteria(criteria.number),
            criteria.state.nonCompliant,
          ])
          .concat(
            needInterventionsCriterias
              .filter((criteria) => criteria.topic === "images")
              .map((criteria) => [
                number_to_image_criteria(criteria.number),
                criteria.state.needIntervention,
              ])
          )
      );
    }, [needInterventionsCriterias, nonCompliantCriterias]);

  const picutresNeededInterventions: UnionTypeArray<
    InterventionRequirement,
    AllPicturesNeededInterventionTypes
  > = useMemo(() => {
    return Object.fromEntries(
      ALL_PICTURES_NEEDED_INTERVENTION_TYPES.map((type) => [
        type,
        pageReport?.neededInterventions.filter(
          (inter) => inter.type === type
        ) || [],
      ])
    ) as UnionTypeArray<
      InterventionRequirement,
      AllPicturesNeededInterventionTypes
    >;
  }, [pageReport?.neededInterventions]);

  return (
    <Box sx={{ width: "100%" }}>
      <SidebarTopicHeader topic="images" />
      <Box
        sx={{
          margin: "25px 8px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Box>
          <CriteriaStateAccordion criteriaState="needIntervention">
            <AccordionPictoLink
              title="Type d'image"
              Icon={(props) => (
                <ImageIcon {...props} stroke={palette.common.white} />
              )}
              type="warning"
              xPaths={picutresNeededInterventions.pictureUsefulness.map(
                (inter) => inter.xPath
              )}
              retrivalKey="pictureUsefulness"
            />
            <AccordionPictoLink
              title="Pertinence de l'alternative textuel"
              Icon={(props) => <InformativeImage {...props} fill="white" />}
              type="warning"
              xPaths={picutresNeededInterventions.pictureAltTextRelevance.map(
                (inter) => inter.xPath
              )}
              retrivalKey="pictureUsefulness"
            />
          </CriteriaStateAccordion>
        </Box>
        <Box>
          <CriteriaStateAccordion criteriaState="nonCompliant">
            <AccordionPictoLink
              title="1.1 Image informative mal implémentée"
              Icon={(props) => <InformativeImage {...props} fill="white" />}
              type="error"
              xPaths={
                picturesErrors.alternativeImplemented?.map(
                  (error) => error.x_path
                ) || []
              }
              retrivalKey="pictureUsefulness"
            />
            <AccordionPictoLink
              title="1.2 Image décorative mal implémentée"
              Icon={(props) => <DecorativeImage {...props} fill="white" />}
              type={"error"}
              xPaths={
                picturesErrors.decorativesIgnored?.map(
                  (error) => error.x_path
                ) || []
              }
              retrivalKey="pictureUsefulness"
            />
            <AccordionPictoLink
              title="1.3 Mauvaise alternative textuel"
              Icon={(props) => <InformativeImage {...props} fill="white" />}
              type="error"
              xPaths={
                picturesErrors.revelantAlternative?.map(
                  (error) => error.x_path
                ) || []
              }
              retrivalKey="pictureUsefulness"
            />
            <AccordionPictoLink
              title="1.8 Mauvaise alternative textuel"
              Icon={(props) => <TextIcon {...props} fill="white" />}
              type="error"
              xPaths={
                picturesErrors.textPicture?.map((error) => error.x_path) || []
              }
              retrivalKey="textPicture"
            />
          </CriteriaStateAccordion>
        </Box>
      </Box>
    </Box>
  );
};

export default PicturesSidebarTopicDetails;
