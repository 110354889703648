import { Button, Typography, Box } from '@mui/material';
import ChangePasswordModal from 'components/modals/ChangePasswordModal';
import { useState, useContext } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import { supabase } from 'supabaseClient';
import { StripePortalSessionResponse, StripePortalSessionRequest } from '../../../../../supabase/functions/_shared/definitions';
import { logError } from 'utils/SentryUtils';

const Account: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState('');

  const { session } = useContext(AuthContext)!;

  const handleOpen = () => setOpen(true);

  const handleClickSubscriptionManagment = async () => {
    const payload: StripePortalSessionRequest = {
      returnUrl: window.location.href,
    };
    
    const { data, error } = await supabase.functions.invoke<StripePortalSessionResponse>('stripe-create-portal', {
      method: 'POST',
      body: JSON.stringify(payload),
    });

    if (error) {
      logError("An error occured while generating portal url", error);
      return;
    } else if (!data) {
      logError("No data returned from edge function", new Error('No data returned from stripe-create-portal'));
      return;
    }

    window.open(data.portalUrl, '_blank');
  }

  return (
    <div className="p-5 flex flex-col gap-10">
      {session.user.email && (
        <Box className="flex flex-col gap-2">
          <Typography variant="h6" color="textSecondary">
            Email : {session.user.email}
          </Typography>
        </Box>
      )}

      <Button className="w-fit" variant="contained" color="primary" onClick={handleOpen}>
        Modifier le mot de passe
      </Button>

      {success && <Typography color="primary">{success}</Typography>}

      <Button className="w-fit" variant="contained" color="primary" onClick={handleClickSubscriptionManagment}>
        Gérer mon abonnement
      </Button>

      <ChangePasswordModal open={open} setOpen={setOpen} setSuccess={setSuccess}/>
    </div>
  );
};

export default Account;
