import { Box, Modal } from "@mui/material";
import AddProjectForm from "./AddProjectForm";

interface AddProjectFormProps {
  isOnboardingModalOpen: boolean;
  handleClose: () => void; 
}

const AddProjectModal: React.FC<AddProjectFormProps> = ({isOnboardingModalOpen, handleClose}) => {


  return (
    <Modal
      open={isOnboardingModalOpen}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      onClose={handleClose}
    >
      <Box>
        <AddProjectForm onClose={handleClose} />
      </Box>
    </Modal>
  );
};

export default AddProjectModal;
