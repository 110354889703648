import Paper from "@mui/material/Paper/Paper";
import Tab from "@mui/material/Tab/Tab";
import Tabs from "@mui/material/Tabs/Tabs";
import Typography from "@mui/material/Typography/Typography";
import useCurrentProject from "hooks/useCurrentProject";
import { ReportContext } from "providers/Report";
import React, { useContext, useState } from "react";
import { get_intervention_linked_topic, Topic } from "@ca/report";
import { ReactComponent as HomeIcon } from "../../../assets/icons/icon-home.svg";
import Box from "@mui/material/Box/Box";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge/Badge";
import PicturesSidebarTopicDetails from "./topics-details/PicturesSidebarTopicDetails";
import { ALL_TOPICS, TOPIC_DETAILS } from "definitions/topic_details";
import { useTheme } from "@mui/material/styles";
import SummarySidebarDetails from "./topics-details/SummarySidebarDetails";
import ColorsSidebarTopicDetails from "./topics-details/ColorsSidebarTopicDetails";
import { CARetributionActiveTopicsContext } from "providers/CARetributionActiveTopics";
import MandatoryElementsSidebarTopicDetails from "./topics-details/MandatoryElementsSidebarTopicDetails";
import InformationStructuringSidebarTopicDetails from "./topics-details/informationStructuringSidebarTopicDetails/InformationStructuringSidebarTopicDetails";

interface TopicTab {
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  topic?: Topic;
  details: JSX.Element;
}

const TOPIC_SIDEBAR_DETAILS: Record<Topic, JSX.Element> = {
  images: <PicturesSidebarTopicDetails />,
  colors: <ColorsSidebarTopicDetails />,
  frames: <></>,
  multimedia: <></>,
  table: <></>,
  links: <></>,
  scripts: <></>,
  mandatoryElements: <MandatoryElementsSidebarTopicDetails />,
  informationStructuring: <InformationStructuringSidebarTopicDetails />,
  informationPresentation: <></>,
  forms: <></>,
  navigation: <></>,
  consultation: <></>,
};

const TABS: TopicTab[] = [
  {
    Icon: HomeIcon,
    details: <SummarySidebarDetails />,
  },
  ...ALL_TOPICS.map((topic) => ({
    Icon: TOPIC_DETAILS[topic].Icon,
    topic,
    details: TOPIC_SIDEBAR_DETAILS[topic],
  })),
];

const ThematicTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTab-root": {
    padding: "5px 8px 5px 5px",
    minWidth: "40px",
    minHeight: "40px",
    overflow: "visible",
  },
  "& .MuiTabs-flexContainer": {
    height: "100%",
    justifyContent: "center",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "&.MuiTabs-root": {
    overflow: "visible !important",
    background: `linear-gradient(90deg, ${theme.palette.primary.dark} 45px, transparent)`,
    zIndex: 2,
  },
  "& .MuiTabs-scroller": {
    overflow: "visible !important",
  },
}));

const ThematicTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.secondary.main,
  "&.Mui-selected": {
    color: theme.palette.primary.main,
  },
}));

export interface ScanReportSidebarProps {}

const ScanReportSidebar: React.FC<ScanReportSidebarProps> = () => {
  const project = useCurrentProject();
  const { pageReport } = useContext(ReportContext)!;
  const { activeAll, activeOnlyOneTopic } = useContext(CARetributionActiveTopicsContext)!;
  
  const { palette } = useTheme();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const currentTab = TABS[selectedTabIndex];

  const getTopicNeededInterventions = (topics: Topic[]) => {
    const interventions =
      pageReport?.neededInterventions.filter((intervention) =>
        topics.includes(get_intervention_linked_topic(intervention))
      ) || [];
    return interventions.length;
  };

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTabIndex(newValue);
    const tabDetails = TABS[newValue];
    if (tabDetails.topic) {
      activeOnlyOneTopic(tabDetails.topic);
    } else {
      activeAll();
    }
  }

  return (
    <Paper
      className="w-[450px] shadow-md"
      sx={{
        backgroundColor: "secondary.main",
        borderRight: "2px solid",
        borderColor: "primary.main",
        borderRadius: 0,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <ThematicTabs
        orientation="vertical"
        variant="standard"
        value={selectedTabIndex}
        aria-label="RGAA themes selection"
        onChange={handleTabChange}
      >
        {TABS.map((tab, index) => (
          <ThematicTab
            key={index}
            icon={(
              <Badge
                color="warning"
                badgeContent={tab.topic ? getTopicNeededInterventions([tab.topic]) : undefined}
              >
                {selectedTabIndex === index ? (
                  <tab.Icon stroke="white" fill="white" width='38px' height='38px' />
                ) : (
                  <tab.Icon stroke="white" fill={palette.primary.main} width='38px' height='38px' />
                )}
              </Badge> 
            )}
          />
        ))}
      </ThematicTabs>
      <Box sx={{ width: "100%", flexGrow: "1" }} className="overflow-y-auto">
        <Typography
          variant="h3"
          color={"primary.light"}
          textOverflow={"ellipsis"}
          overflow={"hidden"}
          textAlign={"center"}
        >
          {project?.name}
        </Typography>
        {currentTab.details}
      </Box>
      <Box sx={{ flexGrow: 1, display: "flex", overflow: "hidden" }}></Box>
    </Paper>
  );
};

export default ScanReportSidebar;
