import { Topic } from "@ca/report";
import { ReactComponent as ImagesIcon } from "../assets/icons/topics-icons/images.svg";
import { ReactComponent as ColorsIcon } from "../assets/icons/topics-icons/colors.svg";
import { ReactComponent as FramesIcon } from "../assets/icons/topics-icons/frames.svg";
import { ReactComponent as MultimediaIcon } from "../assets/icons/topics-icons/multimedia.svg";
import { ReactComponent as LinksIcon } from "../assets/icons/topics-icons/links.svg";

export const ALL_TOPICS: Topic[] = [
  "images",
  "frames",
  "colors",
  "multimedia",
  "table",
  "links",
  "scripts",
  "mandatoryElements",
  "informationStructuring",
  "informationPresentation",
  "forms",
  "navigation",
  "consultation",
];

export const ALL_PICTURES_NEEDED_INTERVENTION_TYPES = [
  "pictureUsefulness",
  "pictureAltTextRelevance",
] as const;
export type AllPicturesNeededInterventionTypes =
  (typeof ALL_PICTURES_NEEDED_INTERVENTION_TYPES)[number];

export const getTopicNumber = (topic: Topic) => ALL_TOPICS.indexOf(topic) + 1;
export const getTopicLink = (topic: Topic) =>
  `https://accessibilite.numerique.gouv.fr/methode/criteres-et-tests/#${getTopicNumber(
    topic
  )}`;

export interface TopicDetails {
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  displayTitle: string;
	color: string;
}

export const TOPIC_DETAILS: Record<Topic, TopicDetails> = {
  images: {
    Icon: ImagesIcon,
    displayTitle: "Images",
		color: "#fff8bc",
  },
  frames: {
		Icon: FramesIcon,
    displayTitle: "Cadres",
		color: "#fff8bc",
  },
	colors: {
		Icon: ColorsIcon,
		displayTitle: "Couleurs",
		color: "#00327e",
	},
  multimedia: {
    Icon: MultimediaIcon,
    displayTitle: "Multimédia",
		color: "#fff8bc",
  },
  table: {
    Icon: ImagesIcon,
    displayTitle: "Tableaux",
		color: "#fff8bc",
  },
  links: {
    Icon: LinksIcon,
    displayTitle: "Liens",
		color: "#fff8bc",
  },
  scripts: {
    Icon: ImagesIcon,
    displayTitle: "Scripts",
		color: "#fff8bc",
  },
  mandatoryElements: {
    Icon: ImagesIcon,
    displayTitle: "Éléments obligatoires",
		color: "#fff8bc",
  },
  informationStructuring: {
    Icon: ImagesIcon,
    displayTitle: "Structuration de l'information",
		color: "#fff8bc",
  },
  informationPresentation: {
    Icon: ImagesIcon,
    displayTitle: "Présentation de l'information",
		color: "#fff8bc",
  },
  forms: {
    Icon: ImagesIcon,
    displayTitle: "Formulaires",
		color: "#fff8bc",
  },
  navigation: {
    Icon: ImagesIcon,
    displayTitle: "Navigation",
		color: "#fff8bc",
  },
  consultation: {
    Icon: ImagesIcon,
    displayTitle: "Consultation",
		color: "#fff8bc",
  },
};
