import { TextField, Button, Typography, Modal, Box } from '@mui/material';
import { useContext, useState } from 'react';
import { supabase } from '../../supabaseClient';
import { AuthContext } from 'providers/AuthProvider';

interface ChangePasswordProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  setSuccess: (open: string) => void;
}

const ChangePasswordModal: React.FC<ChangePasswordProps> = ({ open, setOpen, setSuccess }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const { session } = useContext(AuthContext)!;

  const handlePasswordChange = async () => {
    setError('');
    setSuccess('');

    if (newPassword !== confirmPassword) {
      setError("Les nouveaux mots de passe ne correspondent pas.");
      return;
    }

    try {
      if (!session.user.email) {
        setError("Utilisateur non authentifié.");
        return;
      }

      const { error: signInError } = await supabase.auth.signInWithPassword({
        email: session.user.email,
        password: oldPassword,
      });

      if (signInError) {
        setError("L'ancien mot de passe est incorrect.");
        return;
      }

      const { error: updateError } = await supabase.auth.updateUser({
        password: newPassword,
      });

      if (updateError) {
        setError("Erreur lors de la mise à jour : " + updateError.message);
      } else {
        setSuccess("Mot de passe mis à jour avec succès !");
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setOpen(false);
      }
    } catch (error) {
      setError("Une erreur inattendue s'est produite.");
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setError('');
    setSuccess('');
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6" component="h2" gutterBottom>
          Changer le mot de passe
        </Typography>
        {error && <Typography color="error">{error}</Typography>}

        <div className="flex flex-col gap-4 mt-4">
          <TextField
            label="Ancien mot de passe"
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            fullWidth
          />
          <TextField
            label="Nouveau mot de passe"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            fullWidth
          />
          <TextField
            label="Confirmer le nouveau mot de passe"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
          />
        </div>
        <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
          <Button onClick={handleClose} color="secondary">
            Annuler
          </Button>
          <Button onClick={handlePasswordChange} color="primary" variant="contained">
            Mettre à jour
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChangePasswordModal;