/* eslint-disable import/no-webpack-loader-syntax */
import { createContext, ReactEventHandler, ReactNode, useCallback, useState } from "react";
import useCookiePopupRemover from "./useCookiePopupRemover";
import useFrameCurrentProjectPage, { ProjectPage } from "./useFrameCurrentProjectPage";

export interface CheckAccessFrameContextProps {
  frame: HTMLIFrameElement | null;
  contentDocument: Document | null;
  handleFrameLoad: ReactEventHandler<HTMLIFrameElement>;
  currentProjectPage: ProjectPage;
}

const CheckAccessFrameContext =
  createContext<CheckAccessFrameContextProps | null>(null);


const CheckAccessFrameProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [frame, setFrame] = useState<HTMLIFrameElement | null>(null);
  
  const currentProjectPage = useFrameCurrentProjectPage(frame);
  useCookiePopupRemover(frame?.contentDocument || null);

  const handleFrameLoad: ReactEventHandler<HTMLIFrameElement> = useCallback((evt) => {
    setFrame(evt.currentTarget);
  }, []);

  return (
    <CheckAccessFrameContext.Provider
      value={{
        currentProjectPage,
        frame,
        handleFrameLoad,
        contentDocument: frame?.contentDocument || null,
      }}
    >
      {children}
    </CheckAccessFrameContext.Provider>
  );
};

export { CheckAccessFrameContext, CheckAccessFrameProvider };
