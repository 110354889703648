import { useCallback, useContext } from "react";
import {
  Enums,
  InsertTables,
  supabase,
} from "../supabaseClient";
import { logError } from "utils/SentryUtils";
import useAuth from "./useAuth";
import { OrganizationsContext } from "providers/OrganizationsProvider";
import useCreateProject from "./useCreateProject";

export interface NewProjectPayload {
  name: string;
  url: URL;
}

export interface NewOrganizationPayload {
  organization: Omit<InsertTables<"organizations">, "owner_id" | "id">;
  projectPayload: NewProjectPayload;
}

const useCreateOrganization = () => {
	const { userId } = useAuth();
	const { fetchOrganizations } = useContext(OrganizationsContext)!;
  const createProject = useCreateProject();
	
  const createOrganization = useCallback(
    async ({ organization, projectPayload }: NewOrganizationPayload) => {
      const { data, error } = await supabase
        .from("organizations")
        .insert({
          ...organization,
          owner_id: userId,
        })
        .select("id")
        .single();

      if (error) {
        logError("enable to insert Organisation:", error);
        return;
      }

      await createProject({
        ...projectPayload,
        organizationId: data.id, 
      });
      
    },
    [fetchOrganizations, userId]
  );

	return createOrganization;
};

export default useCreateOrganization;
