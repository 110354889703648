import Add from "@mui/icons-material/Add";
import OnboardingModal from "components/modals/onboarding/OnboaringModal";
import useOrganizations from "../../hooks/useOrganizations";
import { Organization } from "../../providers/OrganizationsProvider";
import ProjectCard from "components/ProjectCard";
import AddOrganizationModal from "components/modals/AddOrganization/AddOrganizationModal";
import { useState } from "react";

const OrganizationDetails: React.FC<{ organization: Organization }> = ({
  organization,
}) => {
  return (
    <div className="m-5">
      <h1 className="text-2xl mb-3 font-bold">{organization.name}</h1>
      <div className="flex flex-wrap gap-5">
      {organization.projects.map((project, index) => (
        <ProjectCard key={index} project={project} />
      ))}
      </div>
    </div>
  );
};

const OrganizationsDashboardView: React.FC = () => {
  const organizations = useOrganizations();
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);

  const handleOpen = () => {
    setIsOnboardingModalOpen(true);
  };

  const handleClose = () => {
    setIsOnboardingModalOpen(false);
  };

  return (
    <div className="ml-20 flex flex-col mt-20">
      <div className="flex flex-wrap w-11/12 justify-end">
        <h2 onClick={handleOpen} className="cursor-pointer font-bold  border border-black rounded-lg p-1.5 bg-green-950 
            text-white hover:bg-green-200 hover:text-black transition flex flex-row">
              <Add/> Ajouter une Organization</h2>
      </div>

      {organizations.map((organization, index) => (
        <OrganizationDetails key={index} organization={organization} />
      ))}

      <OnboardingModal />
      <AddOrganizationModal isOnboardingModalOpen={isOnboardingModalOpen} handleClose={handleClose}/>

    </div>
  );
};

export default OrganizationsDashboardView;
