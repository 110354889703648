import DnsOutlined from "@mui/icons-material/DnsOutlined";
import useTheme from "@mui/material/styles/useTheme";
import { BarChart } from "@mui/x-charts/BarChart";
import useMetric from "../../hooks/useMetric";
import { Palette } from "@mui/material";
import { LegendRendererProps } from "@mui/x-charts/ChartsLegend";
import MetricCard from "./MetricCard";
import React from "react";
import { AxisConfig, ChartsYAxisProps } from "@mui/x-charts";
import { MakeOptional } from "@mui/x-date-pickers/internals";
import CountryTickLabels from "./CountryTickLabels";

const legendConfig = (palette: Palette): Partial<LegendRendererProps> => ({
  position: {
    horizontal: "left",
    vertical: "bottom",
  },
  itemMarkHeight: 6,
  labelStyle: {
    fontSize: 12,
    color: palette.grey[700],
  },
  padding: {
    top: 10,
  },
});

const yAxis: MakeOptional<AxisConfig<"band", any, ChartsYAxisProps>, 'id'> = {
  scaleType: "band",
  dataKey: "country_code",
  categoryGapRatio: 0.8,
};
const series = [{ dataKey: "new_adaptify_sessions", label: "Sessions" }];

const AdaptifySessionsByCountryMetric = () => {
  const metric = useMetric("new_sessions_using_adaptify_by_country");

  const { palette } = useTheme();

  return (
    <React.Fragment>
      <MetricCard title="Sessions utilisant Adaptify par pays" Icon={<DnsOutlined />}>
        {metric && metric.length > 0 && (
          <BarChart
            height={200}
            skipAnimation
            dataset={metric}
            layout="horizontal"
            yAxis={[yAxis]}
            xAxis={[{ tickMinStep: 1, disableLine: true }]}
            series={series}
            margin={{ top: 5, left: 35, right: 5 }}
            leftAxis={null}
            colors={[palette.primary.light, palette.secondary.dark]}
            sx={{
              " .MuiChartsLegend-mark": {
                rx: 4,
              },
            }}
            slotProps={{
              bar: {
                ry: 6,
                rx: 6,
              },
              legend: legendConfig(palette),
            }}
          >
            <CountryTickLabels />
          </BarChart>
        )}
        {metric && metric.length === 0 && <div>No data</div>}
      </MetricCard>
    </React.Fragment>
  );
};

export default AdaptifySessionsByCountryMetric;
