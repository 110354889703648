import DnsOutlined from "@mui/icons-material/DnsOutlined";
import useTheme from "@mui/material/styles/useTheme";
import { BarChart } from "@mui/x-charts/BarChart";
import useMetric from "../../hooks/useMetric";
import { Palette } from "@mui/material";
import { LegendRendererProps } from "@mui/x-charts/ChartsLegend";
import MetricCard from "./MetricCard";
import { AxisConfig, BarSeriesType, ChartsXAxisProps } from "@mui/x-charts/models";
import { MakeOptional } from "@mui/x-date-pickers/internals";
import {displaySessionTime} from "../../utils/dayJsUtils"
import { parsePageUrl } from "utils/parsURLUtils";

const legendConfig = (palette: Palette): Partial<LegendRendererProps> => ({
  position: {
    horizontal: "left",
    vertical: "bottom",
  },
  itemMarkHeight: 6,
  labelStyle: {
    fontSize: 12,
    color: palette.grey[700],
  },
  padding: {
    top: 10,
  },
});


const series: MakeOptional<BarSeriesType, "type">[] = [
  { dataKey: "average_time_on_page_adaptify_user", label: "Utilisateur Adaptify", valueFormatter: displaySessionTime},
  { dataKey: "average_time_on_page_non_user", label: "Utilisateur Classique", valueFormatter: displaySessionTime},
] 

const xAxis: MakeOptional<AxisConfig<"band", any, ChartsXAxisProps>, 'id'> = { scaleType: "band", dataKey: "page_url", barGapRatio: 0.2 }

const TimeOnPageByUserType = () => {
  const metric = useMetric("avg_time_on_page_by_user_type");
  
  const { palette } = useTheme();

  return (
    <MetricCard title="Temps passé en minutes sur chaque page par type d'utilisateur" Icon={<DnsOutlined />}>
      {metric && metric.length > 0 && (
        <BarChart
          height={200}
          skipAnimation
          dataset={metric.map(el=>({...el, page_url: parsePageUrl(el.page_url)}))}
          margin={{ top: 5, left: 55, right: 30 }}
          xAxis={[xAxis]}
          yAxis={[
            {
              scaleType: "time",
              tickMinStep: 1,
              valueFormatter: displaySessionTime
            },
          ]}
          series={series}
          colors={[palette.primary.light, palette.secondary.dark]}
          sx={{
            " .MuiChartsLegend-mark": {
              rx: 4,
            },
            margin: 0,
          }}
          slotProps={{
            bar: {
              ry: 4,
              rx: 4,
            },
            legend: legendConfig(palette),
          }}
          
        />
      )}
      {metric && metric.length === 0 && <div>No data</div>}
    </MetricCard>
  );
};

export default TimeOnPageByUserType;
