import dayjs from "dayjs";
import durationPlugin from "dayjs/plugin/duration";
import relativeTimePlugin from "dayjs/plugin/relativeTime";

dayjs.extend(durationPlugin);
dayjs.extend(relativeTimePlugin);

export const displaySessionTime = (seconds: number | null) => {
    if (seconds === null) {
      return "0s";
    }
    
    const duration = dayjs.duration(seconds, "seconds");
        
    const totalMinutes = Math.floor(duration.asMinutes());
    const secondsRest = duration.seconds();

    if (isNaN(totalMinutes)){
      return `${secondsRest}s`;
    }

    return `${totalMinutes}m${secondsRest}s`;
  }