import Add from "@mui/icons-material/Add";
import AddProjectModal from 'components/modals/AddProject/AddProjectModal';
import { useState } from 'react';
import ProjectCard from 'components/ProjectCard';
import useCurrentOrganization from 'hooks/useCurrentOrganization';

const OrganizationDashboardHomeView: React.FC = () => {

  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);

  const handleOpen = () => {
    setIsOnboardingModalOpen(true);
  };

  const handleClose = () => {
    setIsOnboardingModalOpen(false);
  };

  const organization = useCurrentOrganization();

  return (
    <div className="ml-20 flex flex-col mt-20">
      <h1 className="text-2xl font-bold mb-3">{organization.name} Projects overview</h1>
      <div className="flex flex-wrap gap-5">
        {organization.projects.map((projet)=> (<ProjectCard project={projet}/>))}
      </div>
      <div className="flex flex-wrap mt-5">
        <h2 onClick={handleOpen} className="cursor-pointer font-bold my-2 border border-black rounded-lg p-1.5 bg-green-950 
            text-white hover:bg-green-200 hover:text-black transition flex flex-row">
              <Add/> Ajouter un projet</h2>
      </div>
      <AddProjectModal isOnboardingModalOpen={isOnboardingModalOpen} handleClose={handleClose}/>
    </div>
  );
};

export default OrganizationDashboardHomeView;
