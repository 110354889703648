import { useYScale } from "@mui/x-charts";
import styled from "@emotion/styled";
import React from "react";
import { ScaleBand } from "d3-scale";


const FLAG_HEIGHT = 15;

const computeFlagUrl = (countryCode: string) =>
  `https://cdn.jsdelivr.net/gh/lipis/flag-icons/flags/4x3/${countryCode.toLocaleLowerCase()}.svg`;

const StyledCountryFlag = styled("image")(() => ({
  height: FLAG_HEIGHT,
}));

const CountryTickLabels = () => {
  const yAxisScale = useYScale() as ScaleBand<string>;

  // extract from mui-x useTicks code: https://github.com/mui/mui-x/blob/next/packages/x-charts/src/hooks/useTicks.ts
  const offsetConst = yAxisScale.step() - yAxisScale.bandwidth();
  const computeOffset = (countryCode: string) => {
    return yAxisScale(countryCode)! - offsetConst / 2;
  };

  const labelOffset = yAxisScale.step() * 0.5;

  return (
    <React.Fragment>
      {yAxisScale.domain().map((countryCode: string) => (
        <StyledCountryFlag
          key={countryCode}
          y={computeOffset(countryCode) + labelOffset - FLAG_HEIGHT / 2}
          href={computeFlagUrl(countryCode)}
          x={5}
        />
      ))}
    </React.Fragment>
  );
};

export default CountryTickLabels;