import { useCallback, useMemo, useState } from "react";

const WAHTS_CMS_API_KEY = process.env.REACT_APP_WAHTS_CMS_API_KEY!;
const WHATS_CMS_API_URL = `https://whatcms.org/API/Tech?key=${WAHTS_CMS_API_KEY}`;

interface TechDetails {
  name: string;
  id: number;
  version: string;
  categories: string[];
  url: string;
}

interface SocialDetails {
  network: string;
  url: string;
  profile: string;
}

interface WhatsCMSTechDetection {
  results: TechDetails[];
  result: {
    code: number;
    msg: string;
  };
  meta: {
    social: SocialDetails[];
  };
}

export enum SuportedTechs {
  wordpress,
}

export const DEFAULT_DOCUMENTATION_LINK =
  "https://adaptify-docs.hub-access.com/Installation/Installation/Script";
export const DOCUMENTATION_LINKS: Record<SuportedTechs, string> = {
  [SuportedTechs.wordpress]:
    "https://adaptify-docs.hub-access.com/Installation/Installation/Wordpress",
};

export const NOT_FOUND_ERROR_MESSAGE =
  "Le site n'a pas été trouvé, veuillez vérifier le lien";

export const tryIntoSupportedTech = (tech: string): SuportedTechs | null => {
  const supportedTech = Object.values(SuportedTechs)
    .map((supportedtech) => supportedtech.toString().toLowerCase())
    .find((supportedtech) => supportedtech === tech.toLowerCase());

  if (!supportedTech) return null;
  return SuportedTechs[supportedTech as keyof typeof SuportedTechs];
};

const useTechDetection = () => {
  const [techDetails, setTechDetails] = useState<WhatsCMSTechDetection | null>(
    null
  );

  const detectUrl = useCallback(async (url: URL) => {
    const data = await fetch(`${WHATS_CMS_API_URL}&url=${url.hostname}`);
    const parsedData = await data.json();
    setTechDetails(parsedData);
    return parsedData.result.code !== 202;
  }, []);

  const cms = useMemo(() => {
    if (!techDetails) return null;
    return (
      techDetails.results.find((result) => result.categories.includes("CMS"))
        ?.name || null
    );
  }, [techDetails]);

  return {
    detectUrl,
    techDetails,
    cms,
  };
};

export default useTechDetection;
