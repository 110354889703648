import React from 'react';
import Router from './router';
import AuthProvider from './providers/AuthProvider';
import { OrganizationProvider } from './providers/OrganizationsProvider';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ConfettiProvider from 'providers/ConfettiProvider';
import * as Sentry from '@sentry/react';
import ErrorFallback from 'components/ErrorFallback';

let theme = createTheme({
  palette: {
    primary: {
      main: '#325B4E',
      light: '#325B4E',
      dark: '#5da88d',
    },
    secondary: {
      main: '#5eae92',
      light: '#5eae92',
      dark: '#89e8c6',
    },
    common: {
      black: '#0C1F19',
      white: '#FEFCF4',
    },
  },
});
theme = responsiveFontSizes(theme);

function App() {

  const errorFallback: Sentry.FallbackRender = ({
    error, 
  }) => <ErrorFallback
    error={error}
  />

  return (
    <div className='h-screen w-screen'>
      <Sentry.ErrorBoundary
        fallback={errorFallback}
      >
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AuthProvider>
              <OrganizationProvider>
                <ConfettiProvider>
                  <Router />
                </ConfettiProvider>
              </OrganizationProvider>
            </AuthProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </Sentry.ErrorBoundary>
    </div>
  );
}

export default App;
