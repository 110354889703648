import Accordion from "@mui/material/Accordion/Accordion";
import { AccordionTypeMap } from "@mui/material/Accordion/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary/AccordionSummary";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography/Typography";
import AccordionDetails from "@mui/material/AccordionDetails/AccordionDetails";
import { CRITERIA_STATE_DETAILS, CriteriaStateTypes } from "definitions/criteria_state_details";

const StyledAccordion = styled(
  (props: AccordionTypeMap["props"] & { color: string }) => (
    <Accordion {...props} color={undefined} elevation={0} />
  )
)(({ color, theme }) => ({
  [`&.MuiPaper-root`]: {
    backgroundColor: "transparent",
    color: theme.palette.common.black,
    [`& > .MuiAccordion-heading`]: {
      all: "inherit",
      borderBottom: `6px solid ${color}`,
      fontSize: "1.2rem",
      backgroundColor: theme.palette.common.white,
      "& .MuiButtonBase-root": {
        minHeight: "0",
      },
      "& .MuiAccordionSummary-content": {
        margin: "8px 0",
      },
    },
  },
}));

export interface CriteriaStateAccordionProps {
  criteriaState: CriteriaStateTypes;
  children?: React.ReactNode;
  expanded?: boolean;
}

const CriteriaStateAccordion: React.FC<CriteriaStateAccordionProps> = ({
  criteriaState,
  children,
  expanded,
}) => {
  const { palette } = useTheme();
  const { Icon, paletteTheme, displayTitle } = CRITERIA_STATE_DETAILS[criteriaState];
  const themeColor = palette[paletteTheme].main;

  return (
    <StyledAccordion expanded={expanded} color={themeColor}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon stroke={palette.common.black} />}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Icon stroke={themeColor} />
          <Typography sx={{ ml: 1 }} variant="h6">
            {displayTitle}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          "&.MuiAccordionDetails-root": {
            padding: "8px 0px 16px 0px",
          },
        }}
      >
        {children}
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default CriteriaStateAccordion;
