import React, { useCallback, useMemo, useState } from "react";
import {
  Typography,
  Input,
} from "@mui/material";
import DocumentationDetectionStep from "../DocumentationDetectionStep";
import useCreateOrganization, { NewProjectPayload } from "hooks/useCreateOrganization";
import { StepsModal } from "../StepsModal";
import useProjectStep from "../stepsHooks/useProjectStep";

interface OnboardingFormProps {
  onClose: () => void;
}

const OnboardingForm: React.FC<OnboardingFormProps> = ({ 
  onClose 
}) => {
  const createOrganization = useCreateOrganization();

  const [organisationName, setOrganisationName] = useState("");
  
  const handleSubmit = useCallback(async (projectPayload: NewProjectPayload) => {
    if (!projectPayload.url) return null;
    
    await createOrganization({
      organization: {
        name: organisationName,
      },
      projectPayload,
    });
    
    return null;
  }, [createOrganization, organisationName]);
  
  const welcomeStep: StepsModal.Step = useMemo(
    () => ({
      label: "Bienvenue!",
      StepComponent: (
        <Typography className="flex flex-col">
          <strong className="text-background text-2xl mb-2">
            Bienvenue sur Adaptify!
          </strong>
          Pour commencer, veuillez remplir les informations suivantes.
        </Typography>
      ),
    }),
    []
  );

  const organisationStep: StepsModal.Step = useMemo(
    () => ({
      label: "Organisation",
      validateStep: async () => {
        if (organisationName.trim().length === 0) {
          return "Le nom de l'organisation est requis";
        }
        return null;
      },
      StepComponent: (
        <Typography className="flex flex-col">
          <label htmlFor="organizationName">
            Quel est le nom de votre entreprise?
          </label>
          <Input
            id="organizationName"
            name="organizationName"
            value={organisationName}
            onChange={(e) => setOrganisationName(e.target.value)}
          />
        </Typography>
      ),
    }),
    [organisationName]
  );

  const { step: projectStep, detectedCms } = useProjectStep({ handleSubmit });

  const documentationStep: StepsModal.Step = useMemo(() => ({
    label: "Documentation",
    StepComponent: (
      <DocumentationDetectionStep detectedCms={detectedCms} />
    ),
    canGoBack: false,
  }), [detectedCms]);

  const endStep: StepsModal.Step = useMemo(() => ({
    label: "Fin",
    StepComponent: (
      <Typography variant="h6">Formulaire complété !</Typography>
    ),
  }), []);

  return (
    <StepsModal.Form
      steps={[
        welcomeStep,
        organisationStep,
        projectStep,
        documentationStep,
        endStep,
      ]}
      onClose={onClose}
    />
  );
};

export default OnboardingForm;
