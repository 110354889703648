import { Intervention, PictureScan, PictureUsefulness } from "@ca/report";
import React, { useContext, useMemo, useState } from "react";
import { ReactComponent as DecorativeImage } from "../../../../../assets/icons/icon-decorative-image.svg";
import { ReactComponent as InformativeImage } from "../../../../../assets/icons/icon-informative-image.svg";
import FormControl from "@mui/material/FormControl/FormControl";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as ImageIcon } from "../../../../../assets/icons/icon-image.svg";
import { ReportContext } from "providers/Report";
import { match } from "utils/tsUtils";
import RetributionButton, { RetributionButtonProps, RetributionButtonType } from "../../RetributionButton";

export type PictureAlternativeTextInterventionProps = {
  pictureScan: PictureScan;
  intervention: Intervention.pictureUsefulness | null;

  needIntervention: boolean;

  onUpdateIntervention: (intervention: Intervention.pictureUsefulness) => void;
};

type PictureType =
  | PictureUsefulness.decorative["type"]
  | NonNullable<PictureUsefulness.informative>["type"];

const hasGoodAlternative = (
  intervention: Intervention.pictureUsefulness | null
): boolean | null => {
  if (intervention?.usefulness.type === "informative") {
    return intervention.usefulness.hasGoodAlternative || null;
  }
  return null;
};

const PictureAlternativeTextIntervention: React.FC<
  PictureAlternativeTextInterventionProps
> = ({
  pictureScan,
  needIntervention,
  intervention,

  onUpdateIntervention,
}) => {
  const baseType = intervention?.usefulness?.type;
  const [imageType, setImageType] = useState<PictureType | null>(
    intervention?.usefulness?.type || null
  );

  const [isAltTextCorrect, setIsAltTextCorrect] = useState<boolean | null>(
    hasGoodAlternative(intervention)
  );

  const { findErrorsByPathAndTypes } = useContext(ReportContext)!;

  const errors = useMemo(
    () =>
      findErrorsByPathAndTypes(pictureScan.xPath, [
        "textAlternativeNotRevelant",
        "badTextAlternativeImplementation",
        "pictureNotCorretlyIgnored",
      ]),
    [findErrorsByPathAndTypes, pictureScan.xPath]
  );

  const handleImageTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setImageType((event.target as HTMLInputElement).value as PictureType);
  };

  const handleAltTextCorrectChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsAltTextCorrect((event.target as HTMLInputElement).value === "yes");
  };

  const handleSubmitInfo = () => {
    if (!imageType) return;
    if (imageType === "informative" && isAltTextCorrect === null) return;

    const usefulness: PictureUsefulness =
      imageType === "informative"
        ? {
            type: "informative",
            hasGoodAlternative: isAltTextCorrect!,
            hasGoodLongDescription: undefined,
            canTextBeReplacedByStyledText:
              intervention?.usefulness.type === "informative"
                ? intervention.usefulness.canTextBeReplacedByStyledText
                : true,
          }
        : {
            type: "decorative",
          };

    onUpdateIntervention({
      type: "pictureUsefulness",
      image_hash: pictureScan.imageHash,
      usefulness,
    });
  };

  let radioIsAltTextCorrect = null;
  if (isAltTextCorrect) {
    radioIsAltTextCorrect = "yes";
  } else if (isAltTextCorrect === false) {
    radioIsAltTextCorrect = "no";
  }

  const errorsMessages = useMemo(
    () =>
      Object.values(errors)
        .map((error) =>
          match(error, {
            badTextAlternativeImplementation: (error) => error.details,
            pictureNotCorretlyIgnored: (error) => error.details,
            textAlternativeNotRevelant: () =>
              "Le text alternatif de l'image à été jugé non pertinent",
            textPictureShouldBeReplaceByStyledText: () =>
              "L'image devrait être remplacée par du texte stylisé",
          })
        )
        .filter((error): error is string => error !== null),
    [errors]
  );

  const type: RetributionButtonType = needIntervention
    ? "warning"
    : errorsMessages.length > 0
    ? "error"
    : "info";

  let title = null;
  if (type === "warning") {
    title =
      "Nous avons besoin de plus d'information pour categoriser cette image.";
  } else if (errorsMessages.length > 1) {
    title = `${errorsMessages.length} erreurs ont été détectées sur cette image`;
  } else if (errorsMessages.length === 1) {
    title = errorsMessages[0];
  } else {
    title =
      "Tout est bon! Vous pouvez modifier les informations de l'image si vous le souhaitez";
  }

  let Icon: RetributionButtonProps["Icon"];
  if (baseType === "informative") {
    Icon = (props) => <InformativeImage {...props} fill="white" />;
  } else if (baseType === "decorative") {
    Icon = (props) => <DecorativeImage {...props} fill="white" />;
  } else {
    Icon = (props) => <ImageIcon {...props} stroke="white" />;
  }

  return (
    <RetributionButton
      retrivalId={`pictureUsefulness-${pictureScan.xPath}`}
      type={type}
      Icon={Icon}
      xPath={pictureScan.xPath}
      tooltipProps={{
        title,
        content: (
          <FormControl component="fieldset" sx={{ width: "100%" }}>
            <Typography
              sx={{
                marginBottom: "0px",
                lineHeight: "1",
              }}
            >
              Quel est le type de l'image ?
            </Typography>
            <RadioGroup value={imageType} onChange={handleImageTypeChange}>
              <FormControlLabel
                sx={{
                  marginBottom: "-12px",
                  marginTop: "-2px",
                }}
                slotProps={{
                  typography: {
                    variant: "subtitle1",
                  },
                }}
                value="decorative"
                control={<Radio size="small" color={type} />}
                label="Décorative"
              />
              <FormControlLabel
                value="informative"
                control={<Radio size="small" color={type} />}
                label="Informative"
              />
            </RadioGroup>
            {imageType === "informative" && (
              <Box sx={{ mt: 1 }}>
                <Typography>Texte alternatif de l'image:</Typography>
                <Typography variant="caption">
                  "{pictureScan.altText}"
                </Typography>
                <RadioGroup
                  value={radioIsAltTextCorrect}
                  onChange={handleAltTextCorrectChange}
                >
                  <FormControlLabel
                    value={"yes"}
                    sx={{
                      marginBottom: "-12px",
                      marginTop: "-2px",
                    }}
                    control={<Radio size="small" color={type} />}
                    label="Le texte alternatif est correct"
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio size="small" color={type} />}
                    label="Le texte alternatif n'est pas correct"
                  />
                </RadioGroup>
              </Box>
            )}
            <Button
              variant="contained"
              sx={{
                mt: 2,
                borderRadius: "32px",
              }}
              color={type}
              onClick={handleSubmitInfo}
            >
              Valider
            </Button>
          </FormControl>
        ),
        referenceLink:
          "https://accessibilite.numerique.gouv.fr/methode/criteres-et-tests/#1.3",
      }}
    />
  );
};

export default PictureAlternativeTextIntervention;
