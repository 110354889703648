import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import React, { ReactNode } from "react";
import Typography from "@mui/material/Typography";
import SidebarButton from "./SidebarButton";
import LanguageIcon from "@mui/icons-material/Language";
import SettingsIcon from "@mui/icons-material/Settings";
import { ORGANIZATIONS_URL } from "router";

const SidebarCategory: React.FC<{
  children: ReactNode;
  title: string;
}> = ({ children, title }) => {
  return (
    <>
      <Typography
        variant="h6"
        noWrap
        sx={{
          mt: 5,
        }}
      >
        {title}
      </Typography>
      {children}
    </>
  );
};

const Sidebar: React.FC<{
  width: number;
  children?: ReactNode;
}> = ({ width, children }) => {
  return (
    <Drawer
      sx={{
        width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width,
          boxSizing: "border-box",
          backgroundColor: "common.black",
          color: "common.white",
          display: "flex",
          flexDirection: "column",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <SidebarButton
        Icon={LanguageIcon}
        link={ORGANIZATIONS_URL}
        text="Hub-Access"
        target="_self"
      />
      <Toolbar />
      <List sx={{ p: 3 }}>{children}</List>
      <SidebarButton
        Icon={SettingsIcon}
        link="/settings"
        text="Settings"
        target="_self"
        isLast={true}
      />
    </Drawer>
  );
};

export { Sidebar, SidebarCategory };
