import { Topic } from "@ca/report";
import Box from "@mui/material/Box/Box";
import IconButton from "@mui/material/IconButton/IconButton";
import Typography from "@mui/material/Typography/Typography";
import { getTopicLink, TOPIC_DETAILS } from "definitions/topic_details";
import React from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TopicScoreBarChart from "../TopicScoreBarChart";

export interface SidebarTopicHeaderProps {
  topic: Topic;
  titleFontSize?: string;
}

const SidebarTopicHeader: React.FC<SidebarTopicHeaderProps> = ({
  topic,
  titleFontSize,
}) => {
  const topicDetails = TOPIC_DETAILS[topic];

  return (
    <>
      <Box
        sx={{
          color: "common.white",
          display: "flex",
          alignItems: "end",
          justifyContent: "center",
          mt: 1,
          mb: 2,
        }}
      >
        <topicDetails.Icon
          stroke={topicDetails.color}
          width="40px"
          height="40px"
        />
        <Typography
          variant="h5"
          fontSize={`${titleFontSize || '1.75rem'} !important`}
          textAlign={"center"}
        >
          {topicDetails.displayTitle.toUpperCase()}
        </Typography>
        <IconButton onClick={() => window.open(getTopicLink(topic), "_blank")}>
          <OpenInNewIcon
            color="primary"
            sx={{ fontSize: 21 }}
          />
        </IconButton>
      </Box>
      <TopicScoreBarChart topics={[topic]} />
    </>
  );
};

export default SidebarTopicHeader;
