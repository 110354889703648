import Box from "@mui/material/Box/Box";
import React, { useCallback, useContext, useMemo, useState } from "react";
import SidebarTopicHeader from "../SidebarTopicHeader";
import { Intervention } from "@ca/report";
import { getCriteriaState, ReportContext } from "providers/Report";
import useInformationStructuringFrameRetribution, {
  DisplayedNodeType,
} from "./useInformationStructuringFrameRetribution";
import CriteriaAccordion from "../../accordion/CriteriaAccordion";
import { CRITERIA_STATE_DETAILS } from "definitions/criteria_state_details";
import { useTheme } from "@mui/material";
import { InterventionsContext } from "providers/InterventionsProvider";
import { CheckAccessFrameContext } from "providers/CheckAccessFrame/CheckAccessFrame";
import {
  GeneralStructureCriteriaForm,
  ListsCriteriaForm,
  TitlesCriteriaForm,
} from "./InterventionForms";

export interface InformationStructuringSidebarTopicDetailsProps {}

const InformationStructuringSidebarTopicDetails: React.FC<
  InformationStructuringSidebarTopicDetailsProps
> = () => {
  const [displayedNodeType, setDisplayedNodeType] =
    useState<DisplayedNodeType | null>(null);
  const frameRetribution =
    useInformationStructuringFrameRetribution(displayedNodeType);

  const { pageReport, reportCurrentPage } = useContext(ReportContext)!;
  const { palette } = useTheme();
  const { infos, addIntervention } = useContext(InterventionsContext)!;
  const { currentProjectPage } = useContext(CheckAccessFrameContext)!;

  const [criteriaAccordionOpen, setCriteriaAccordionOpen] = useState<
		number | null
  >(null);

	const handleActiveAccordionChange = useCallback((index: number) => {
		if (criteriaAccordionOpen === index) setDisplayedNodeType(null);
		setCriteriaAccordionOpen(criteriaAccordionOpen === index ? null : index);
	}, [criteriaAccordionOpen])

  const submitIntervention = useCallback(
    (intervention: Intervention) => {
      if (!pageReport) return;

      addIntervention(
        intervention,
        currentProjectPage.id,
        (it) => it.type === intervention.type
      );
      reportCurrentPage();
    },
    [addIntervention, currentProjectPage.id, pageReport, reportCurrentPage]
  );

  const titlesCriteria = useMemo(() => {
    const criteria = pageReport?.criterias.find(
      (criteria) =>
        criteria.topic === "informationStructuring" && criteria.number === 1
    );

    if (!criteria) return null;

    const criteriaState = getCriteriaState(criteria);
    const details = CRITERIA_STATE_DETAILS[criteriaState];

		const intervention = infos[currentProjectPage.id]?.interventions.find((intervention) => intervention.type === "informationStructuringTitles");

    return (
      <CriteriaAccordion
        title={"9.1 - Structuration des titres"}
        Icon={<details.Icon stroke={palette[details.paletteTheme].main} />}
        type={details.paletteTheme}
				expanded={criteriaAccordionOpen === 0}
				onExpandedChange={() => handleActiveAccordionChange(0)}
      >
        <TitlesCriteriaForm
					isActive={criteriaAccordionOpen === 0}
          paletteTheme={details.paletteTheme}
          onSubmit={submitIntervention}
					baseIntervention={intervention as Intervention.informationStructuringTitles}
          onChangeDisplayedNodeType={setDisplayedNodeType}
        />
      </CriteriaAccordion>
    );
  }, [criteriaAccordionOpen, currentProjectPage.id, handleActiveAccordionChange, infos, pageReport?.criterias, palette, submitIntervention]);

  const generalStructureCriteria = useMemo(() => {
    const criteria = pageReport?.criterias.find(
      (criteria) =>
        criteria.topic === "informationStructuring" && criteria.number === 2
    );

    if (!criteria) return null;

    const criteriaState = getCriteriaState(criteria);
    const details = CRITERIA_STATE_DETAILS[criteriaState];

		const intervention = infos[currentProjectPage.id]?.interventions.find((intervention) => intervention.type === "informationStructuringGeneral");

    return (
      <CriteriaAccordion
        title={"9.2 - Structure générale"}
        Icon={<details.Icon stroke={palette[details.paletteTheme].main} />}
        type={details.paletteTheme}
				expanded={criteriaAccordionOpen === 1}
				onExpandedChange={() => handleActiveAccordionChange(1)}
      >
        <GeneralStructureCriteriaForm
					isActive={criteriaAccordionOpen === 1}
          paletteTheme={details.paletteTheme}
          onSubmit={submitIntervention}
					baseIntervention={intervention as Intervention.informationStructuringGeneral}
          onChangeDisplayedNodeType={setDisplayedNodeType}
        />
      </CriteriaAccordion>
    );
  }, [criteriaAccordionOpen, currentProjectPage.id, handleActiveAccordionChange, infos, pageReport?.criterias, palette, submitIntervention]);

  const listsCriteria = useMemo(() => {
    const criteria = pageReport?.criterias.find(
      (criteria) =>
        criteria.topic === "informationStructuring" && criteria.number === 3
    );

    if (!criteria) return null;

    const criteriaState = getCriteriaState(criteria);
    const details = CRITERIA_STATE_DETAILS[criteriaState];

		const intervention = infos[currentProjectPage.id]?.interventions.find((intervention) => intervention.type === "informationStructuringLists");

    return (
      <CriteriaAccordion
        title={"9.3 - Structuration des lists"}
        Icon={<details.Icon stroke={palette[details.paletteTheme].main} />}
        type={details.paletteTheme}
				expanded={criteriaAccordionOpen === 2}
				onExpandedChange={() => handleActiveAccordionChange(2)}
      >
        <ListsCriteriaForm
					isActive={criteriaAccordionOpen === 2}
          paletteTheme={details.paletteTheme}
          onSubmit={submitIntervention}
					baseIntervention={intervention as Intervention.informationStructuringLists}
          onChangeDisplayedNodeType={setDisplayedNodeType}
        />
      </CriteriaAccordion>
    );
  }, [criteriaAccordionOpen, currentProjectPage.id, handleActiveAccordionChange, infos, pageReport?.criterias, palette, submitIntervention]);

  return (
    <Box sx={{ width: "100%" }}>
      <SidebarTopicHeader topic="informationStructuring" />
      <Box
        sx={{
          margin: "25px 8px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Box>
          {titlesCriteria}
          {generalStructureCriteria}
          {listsCriteria}
        </Box>
      </Box>
      {frameRetribution}
    </Box>
  );
};

export default InformationStructuringSidebarTopicDetails;
