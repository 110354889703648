import Box from "@mui/material/Box/Box";
import React, { useContext, useMemo } from "react";
import SidebarTopicHeader from "./SidebarTopicHeader";
import AccordionPictoLink from "../accordion/AccordionPictoLink";
import CriteriaStateAccordion from "../accordion/CriteriaStateAccordion";
import { ReactComponent as ContrastIcon } from "../../../../assets/icons/icon-contrast.svg";
import { NonCompliantError } from "@ca/report";
import { ReportContext } from "providers/Report";

export interface ColorsSidebarTopicDetailsProps {}

const ColorsSidebarTopicDetails: React.FC<
  ColorsSidebarTopicDetailsProps
> = () => {
  const { allErrors } = useContext(ReportContext)!;
  const constrastErrors = useMemo(
    () =>
      allErrors.filter(
        (error) => error.type === "badTextContrast"
      ) as NonCompliantError.badTextContrast[],
    [allErrors]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <SidebarTopicHeader topic="colors" />
      <Box
        sx={{
          margin: "25px 8px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Box>
          <CriteriaStateAccordion criteriaState="nonCompliant">
            <AccordionPictoLink
              title="3.2 Contraste du texte"
              Icon={(props) => <ContrastIcon {...props} fill="white" stroke="white" />}
              type="error"
              xPaths={constrastErrors.map((error) => error.x_path)}
              retrivalKey="textContrast"
            />
          </CriteriaStateAccordion>
        </Box>
      </Box>
    </Box>
  );
};

export default ColorsSidebarTopicDetails;
