import { PageScan } from "providers/ScanerProvider";
import { useContext } from "react";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import React from "react";
import { CheckAccessFrameContext } from "providers/CheckAccessFrame/CheckAccessFrame";
import { CARetributionActiveTopicsContext } from "providers/CARetributionActiveTopics";
import PicturesRetribution from "./FrameTopicRetribution/PicturesRetribution";
import ColorsRetribution from "./FrameTopicRetribution/ColorsRetribution";
import useScaner from "hooks/useScaner";
import MandatoryElementsRetribution from "./FrameTopicRetribution/MandatoryElementsRetribution";
import useCurrentProject from "hooks/useCurrentProject";

const API_URL = process.env.REACT_APP_CHECK_ACCESS_API_URL!;

const getScanUrl = (scan: PageScan): string => {
  return `${API_URL}/preview_scan_source?scanId=${scan.scan_id}&pageId=${scan.page_id}`;
};

interface ScanReportRetributionProps {}

const ScanReportRetribution: React.FC<ScanReportRetributionProps> = () => {
  const { isTopicActivated, isTheOnlyTopicActivated } = useContext(CARetributionActiveTopicsContext)!;
  const { contentDocument, handleFrameLoad, currentProjectPage } = useContext(
    CheckAccessFrameContext
  )!;
  const currentProject = useCurrentProject();
  const { currentPageScan } = useScaner();

  const cache = createCache({
    key: "css",
    ...(contentDocument && {
      container: contentDocument.head,
    }),
    prepend: true,
  });

  if (!currentPageScan) {
    return (
      <iframe
        className="grow"
        title="website-preview"
        src={`${API_URL}${currentProjectPage.path}?ca_loaded_domain=${currentProject.domain}`}
        onLoad={handleFrameLoad}
      />
    );
  }

  return (
    <CacheProvider value={cache}>
      <iframe
        key={currentPageScan.page_id}
        className="grow"
        title="website-preview"
        onLoad={handleFrameLoad}
        src={getScanUrl(currentPageScan)}
      ></iframe>
      {isTopicActivated("images") && <PicturesRetribution />}
      {isTopicActivated("colors") && <ColorsRetribution />}
      {isTheOnlyTopicActivated("mandatoryElements") && <MandatoryElementsRetribution />}
    </CacheProvider>
  );
};

export default ScanReportRetribution;
