import { CheckAccessFrameContext } from "providers/CheckAccessFrame/CheckAccessFrame";
import { ReportContext } from "providers/Report";
import React, { useContext, useMemo } from "react";
import { ErrorRetributionButton } from "../RetributionButton";
import { ReactComponent as ContrastIcon } from "../../../../assets/icons/icon-contrast.svg";
import { NonCompliantError } from "@ca/report";
import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography/Typography";
import RetributionButtonPopper from "../RetributionButtonPopper";

export interface ColorsRetributionProps {}

const ColorsRetribution: React.FC<ColorsRetributionProps> = () => {
  const { contentDocument } = useContext(CheckAccessFrameContext)!;
  const { allErrors } = useContext(ReportContext)!;

  const constrastErrors = useMemo(
    () =>
      allErrors.filter(
        (error) => error.type === "badTextContrast"
      ) as NonCompliantError.badTextContrast[],
    [allErrors]
  );

  const constrastErrorsElements = useMemo(() => {
    if (!contentDocument) return [];

    return constrastErrors
      .map((error) => {
        return (
          <RetributionButtonPopper
            key={error.x_path}
            xPath={error.x_path}
            placement={"bottom"}
            offset={[0, 4]}
          >
            <Box>
              <ErrorRetributionButton
                retrivalId={`textContrast-${error.x_path}`}
                Icon={(props) => (
                  <ContrastIcon {...props} fill="white" stroke="white" />
                )}
                xPath={error.x_path}
                tooltipProps={{
                  title: `Contrast trop bas (${error.contrast.toFixed(2)}:1)`,
                  content: (
										<Box>
											<Typography
												sx={{
													marginBottom: "0px",
													lineHeight: "1",
												}}
											>
												Contrast trop bas entre le texte et le background
											</Typography>
										</Box>
                  ),
                  referenceLink:
                    "https://accessibilite.numerique.gouv.fr/methode/criteres-et-tests/#3.2",
                }}
              />
            </Box>
          </RetributionButtonPopper>
        );
      })
      .filter((element) => element !== null);
  }, [constrastErrors, contentDocument]);

  return <React.Fragment>{constrastErrorsElements}</React.Fragment>;
};

export default ColorsRetribution;
