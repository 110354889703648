import Box from "@mui/material/Box/Box";
import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import RetributionIcon from "components/check-access/scanReportRetribution/RetributionIcon";
import CriteriaAccordion from "./CriteriaAccordion";
import { XPathFinderContext } from "providers/XPathFinder";


export interface AccordionPictoLinkProps {
  title: string;
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  type: "info" | "warning" | "error" | "success";
  xPaths: string[];
  retrivalKey: string;
}

const AccordionPictoLink: React.FC<AccordionPictoLinkProps> = ({
  title,
  Icon,
  type,
  xPaths,
  retrivalKey,
}) => {
  const { palette } = useTheme();
  const color = palette[type].main;

  const { scrollPictoIntoView } = useContext(XPathFinderContext)!;

  const isEmpty = xPaths.length === 0;

  return (
    <CriteriaAccordion
      title={title}
      Icon={<RetributionIcon Icon={Icon} color={color} />}
      type={type}
      badgeValue={xPaths.length}
      defaultIsExpanded={!isEmpty}
    >
      <Box sx={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
        {xPaths.map((path, index) => (
          <RetributionIcon
            onClick={() => scrollPictoIntoView(retrivalKey, path)}
            Icon={Icon}
            key={index}
            color={color}
          />
        ))}
      </Box>
    </CriteriaAccordion>
  );
};

export default AccordionPictoLink;
