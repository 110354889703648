import { Box } from '@mui/material';
import useCurrentProject from 'hooks/useCurrentProject';
import useTechDetection, { DEFAULT_DOCUMENTATION_LINK, DOCUMENTATION_LINKS, tryIntoSupportedTech } from 'hooks/useTechDetection';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const ProjectDashboardHomeView: React.FC = () => {
  const project = useCurrentProject();
  const { cms: detectedCms, detectUrl } = useTechDetection();

  useEffect(() => {
      const domain = new URL(`${project.http_scheme}://${project.domain}`);
      detectUrl(domain);
  }, [detectUrl, project]);

  const supportedTech = detectedCms ? tryIntoSupportedTech(detectedCms) : null;
  const documentationLink = supportedTech != null ? DOCUMENTATION_LINKS[supportedTech] : DEFAULT_DOCUMENTATION_LINK;
  
  let explanationText;
  if (supportedTech) {
    explanationText = <p>Site <strong>{detectedCms}</strong> détecté! Voici la documentation nécessaire à l'installation.</p>;
  } else if (detectedCms) {
    explanationText = <p>Site <strong>{detectedCms}</strong> détecté! Nous n'avons pas encore de documentation spécifique pour votre CMS, voici donc la documentation d'installation générale.</p>;
  } else {
    explanationText = <p>Voici la documentation nécessaire à l'installation.</p>;
  }

  return (
    <div className='flex items-center flex-col'>
      <h1 className="text-center my-5 text-5xl font-bold">{project.name}</h1>
      <Box
        border={1}
        borderColor="black"
        borderRadius="24px"
        width="75%"
        p={5}
      >        
        <h2 className="my-2 text-5xl font-bold" >Documentation</h2>

        {explanationText}

        <Box mt={5} display="flex">
          <NavLink 
            to={documentationLink} 
            target="_blank" 
            className="bg-green-950 text-white hover:bg-green-200 hover:text-black transition shadow-xl border rounded-3xl border-black p-2">
          
            Lien vers la documentation
          
          </NavLink>
        </Box>
      </Box>
    </div>
  );
};

export default ProjectDashboardHomeView;
