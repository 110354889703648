import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { InterventionsContext } from "./InterventionsProvider";
import {
  report_page as wasm_report_page,
  PageReport,
  Topics,
  Criteria,
  CriteriaState,
  NonCompliantError,
} from "@ca/report";
import { ScanerContext } from "./ScanerProvider";
import { logError } from "utils/SentryUtils";
import { FilterByProperty, NullableUnionType } from "utils/tsUtils";
import { CriteriaStateTypes } from "definitions/criteria_state_details";

export type CriteriaByState = {
  compliant: CriteriaState.compliant;
  nonCompliant: CriteriaState.nonCompliant;
  notApplicable: CriteriaState.notApplicable;
  needIntervention: CriteriaState.needIntervention;
};


export type CriteriaWithState<T extends keyof CriteriaByState> = Omit<
  Criteria,
  "state"
> & {
  state: CriteriaByState[T];
};

export type CompliantCriteria = CriteriaWithState<"compliant">;
export type NonCompliantCriteria = CriteriaWithState<"nonCompliant">;
export type NotApplicableCriteria = CriteriaWithState<"notApplicable">;
export type NeedInterventionCriteria = CriteriaWithState<"needIntervention">;

export const getCriteriaState = (criteria: Criteria): CriteriaStateTypes => {
  if (criteria.state === "compliant") return "compliant";
  if (criteria.state === "notApplicable") return "notApplicable";
  if (criteria.state.hasOwnProperty("nonCompliant")) return "nonCompliant";
  if (criteria.state.hasOwnProperty("needIntervention"))
    return "needIntervention";

  throw new Error("Unknown criteria state");
};

export type TargetedNonCompliantErrors = FilterByProperty<
  NonCompliantError,
  "x_path"
>;

export interface ReportContextProps {
  reportCurrentPage: () => void;
  pageReport: PageReport | null;

  compliantCriterias: CompliantCriteria[];
  notApplicableCriterias: NotApplicableCriteria[];
  nonCompliantCriterias: NonCompliantCriteria[];
  needInterventionsCriterias: NeedInterventionCriteria[];

  allErrors: NonCompliantError[];
  allTargetedErrors: TargetedNonCompliantErrors[];

  findErrorByPathAndType: <Type extends TargetedNonCompliantErrors["type"]>(
    xPath: string,
    type: Type
  ) => Extract<TargetedNonCompliantErrors, { type: Type }> | undefined;

  findErrorsByPathAndTypes: <
    Types extends TargetedNonCompliantErrors["type"][]
  >(
    xPath: string,
    types: Types
  ) => NullableUnionType<TargetedNonCompliantErrors, Types[number]>;
}

const TOPICS: Topics = ["colors", "images", "mandatoryElements", "informationStructuring"];

const ReportContext = createContext<ReportContextProps | null>(null);

export type ExtendedPageReport = PageReport & {
  relatedScanId: string;
  relatedPageId: string;
};

const ReportProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { currentPageScan } = useContext(ScanerContext)!;
  const { infos, bulkAddInterventions } = useContext(InterventionsContext)!;
  const [pageReport, setPageReport] = useState<ExtendedPageReport | null>(null);

  const reportCurrentPage = useCallback(() => {
    if (!currentPageScan) {
      setPageReport(null);
      return;
    }

    const pageInfos = infos[currentPageScan.page_id];

    if (!pageInfos) {
      logError(`Page with id ${currentPageScan.page_id} not found`);
      setPageReport(null);
      return;
    }

    const reportResult = wasm_report_page(
      pageInfos,
      currentPageScan.scan,
      TOPICS
    );
    setPageReport({
      ...reportResult,
      relatedPageId: currentPageScan.page_id,
      relatedScanId: currentPageScan.scan_id,
    });

    if (reportResult.guessedInterventions.length > 0) {
      bulkAddInterventions(
        reportResult.guessedInterventions,
        currentPageScan.page_id
      );
    }
  }, [bulkAddInterventions, currentPageScan, infos]);

  useEffect(() => {
    // This is to prevent reportCurrentPage to be called endlessly if report always send new interventions
    if (
      pageReport &&
      currentPageScan &&
      pageReport.relatedScanId === currentPageScan.scan_id &&
      pageReport.relatedPageId === currentPageScan.page_id
    )
      return;
    reportCurrentPage();
  }, [currentPageScan, pageReport, reportCurrentPage]);

  const getCriteriaWithState = useCallback(
    <T extends CriteriaStateTypes>(
      criteriaState: T
    ): CriteriaWithState<T>[] => {
      return pageReport?.criterias.filter(
        (c) => getCriteriaState(c) === criteriaState
      ) as CriteriaWithState<T>[] || [];
    },
    [pageReport?.criterias]
  );

  const compliantCriterias = useMemo(
    () => getCriteriaWithState("compliant"),
    [getCriteriaWithState]
  );
  
  const notApplicableCriterias = useMemo(
    () => getCriteriaWithState("notApplicable"),
    [getCriteriaWithState]
  );

  const nonCompliantCriterias = useMemo(
    () => getCriteriaWithState("nonCompliant"),
    [getCriteriaWithState]
  );

  const needInterventionsCriterias = useMemo(
    () => getCriteriaWithState("needIntervention"),
    [getCriteriaWithState]
  );

  const allErrors = useMemo(
    () =>
      nonCompliantCriterias
        .flatMap((c) => c.state.nonCompliant)
        .concat(
          needInterventionsCriterias.flatMap((c) => c.state.needIntervention)
        )
        .filter((e) => e !== undefined),
    [needInterventionsCriterias, nonCompliantCriterias]
  );

  const allTargetedErrors = useMemo(() => {
    return allErrors.filter(
      (error) => "x_path" in error
    ) as TargetedNonCompliantErrors[];
  }, [allErrors]);

  const findErrorByPathAndType = useCallback(
    <Type extends TargetedNonCompliantErrors["type"]>(
      xPath: string,
      type: Type
    ): Extract<TargetedNonCompliantErrors, { type: Type }> | undefined => {
      return allTargetedErrors.find(
        (error) => error.x_path === xPath && error.type === type
      ) as Extract<TargetedNonCompliantErrors, { type: Type }> | undefined;
    },
    [allTargetedErrors]
  );

  const findErrorsByPathAndTypes = useCallback(
    <Types extends TargetedNonCompliantErrors["type"][]>(
      xPath: string,
      types: Types
    ): NullableUnionType<TargetedNonCompliantErrors, Types[number]> => {
      return Object.fromEntries(
        allTargetedErrors
          .filter(
            (error) => error.x_path === xPath && types.includes(error.type)
          )
          .map((error) => [error.type, error])
      ) as NullableUnionType<TargetedNonCompliantErrors, Types[number]>;
    },
    [allTargetedErrors]
  );

  return (
    <ReportContext.Provider
      value={{
        reportCurrentPage,
        pageReport,

        compliantCriterias,
        notApplicableCriterias,
        nonCompliantCriterias,
        needInterventionsCriterias,

        allErrors,
        allTargetedErrors,

        findErrorByPathAndType,
        findErrorsByPathAndTypes,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export { ReportContext, ReportProvider };
