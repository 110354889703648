import { Infos, Intervention } from "@ca/report";
import useCurrentProject from "hooks/useCurrentProject";
import { ReactNode, createContext, useCallback, useState } from "react";
import { logError } from "utils/SentryUtils";
import { FilterByProperty } from "utils/tsUtils";

type PageId = string;

export type TargetedInterventions = FilterByProperty<Intervention, "x_path">;

type InterventionsContextProps = {
  infos: Record<PageId, Infos>;
  replaceTargetedIntervention: (intervention: TargetedInterventions, pageId: PageId) => void;
  addIntervention: (intervention: Intervention, pageId: PageId, toRemoveCallback: (intervention: Intervention) => boolean) => void;
  bulkAddInterventions: (interventions: Intervention[], pageId: PageId, toRemoveCallback?: (intervention: Intervention) => boolean) => void;
  findInterventionByPathAndType: <Type extends TargetedInterventions["type"]>(
    pageId: string,
    xPath: string,
    type: Type
  ) => Extract<TargetedInterventions, { type: Type }> | undefined;
};

const InterventionsContext = createContext<InterventionsContextProps | null>(
  null
);

const InterventionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const project = useCurrentProject();

  const [infos, setInfos] = useState<Record<PageId, Infos>>(
    project.pages.reduce((acc, page) => {
      const infos = Array.isArray(page.infos["interventions"])
        ? page.infos
        : {
            interventions: [],
          };

      acc[page.id] = infos;
      return acc;
    }, {} as Record<PageId, Infos>)
  );

  const bulkAddInterventions = useCallback(
    (interventions: Intervention[], pageId: PageId, toRemoveCallback?: (intervention: Intervention) => boolean) => {
      setInfos((prev) => {
        const filtredInterventions = toRemoveCallback ? prev[pageId].interventions.filter((el) => !toRemoveCallback(el)) : prev[pageId].interventions;
        
        prev[pageId].interventions = [
          ...filtredInterventions,
          ...interventions,
        ];

        return { ...prev };
      });
    },
    []
  );

  const replaceTargetedIntervention = useCallback((intervention: TargetedInterventions, pageId: PageId) => {
    setInfos((prev) => {
      prev[pageId].interventions = prev[pageId].interventions.filter(
        (i) => i.type !== intervention.type || i.x_path !== intervention.x_path
      );
      prev[pageId].interventions.push(intervention);
      prev[pageId] = { ...prev[pageId] };
      return { ...prev };
    });
  }, []);

  const addIntervention = useCallback(
    (intervention: Intervention, pageId: PageId, toRemoveCallback: (intervention: Intervention) => boolean) => {
      bulkAddInterventions([intervention], pageId, toRemoveCallback);
    },
    [bulkAddInterventions]
  );

  const findInterventionByPathAndType = useCallback(
    <Type extends TargetedInterventions["type"]>(
      pageId: string,
      xPath: string,
      type: Type
    ): Extract<TargetedInterventions, { type: Type }> | undefined => {
      const pageInfos = infos[pageId];
      
      if (!pageInfos) {
        logError(`Page with id ${pageId} not found`);
        return undefined;
      }

      return pageInfos.interventions.find(
        (intervention) =>
          'x_path' in intervention && intervention.x_path === xPath && intervention.type === type
      ) as Extract<TargetedInterventions, { type: Type }> | undefined;
    },
    [infos]
  );

  return (
    <InterventionsContext.Provider
      value={{
        infos,
        addIntervention,
        replaceTargetedIntervention,
        bulkAddInterventions,
        findInterventionByPathAndType,
      }}
    >
      {children}
    </InterventionsContext.Provider>
  );
};

export { InterventionsContext, InterventionProvider };
