import useCurrentProject from "hooks/useCurrentProject";
import { Project } from "hooks/useProject";
import { OrganizationsContext } from "providers/OrganizationsProvider";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Tables } from "supabaseClient";
import { logError } from "utils/SentryUtils";

export type ProjectPage = Tables<"project_pages">;

const constructFullUrl = (path: string, project: Project): URL | null => {
  try {
    return new URL(`${project.http_scheme}://${project.domain}${path}`);
  } catch (error) {
    logError("Failed to construct full URL", { path, project, error });
    return null;
  }
}

const parseUrl = (url: string | URL, currentProject: Project): URL | null => {
	if (url && typeof url === "string") {
		try {
			return new URL(url);
		} catch {
			return constructFullUrl(url, currentProject);
		}
	} else if (url && typeof url === "object") {
		return url;
	}

	return null;
}

const useFrameCurrentProjectPage = (frame: HTMLIFrameElement | null): ProjectPage => {
  const currentProject = useCurrentProject();
	const { insertProjectPage } = useContext(OrganizationsContext)!;
	
  const [currentProjectPage, setCurrentProjectPage] = useState<ProjectPage>(
    currentProject.pages.find((page) => page.path === "/")!
  );

	const insertNewProjectPage = useCallback(async (url: URL) => { 
		const newProjectPageName = url.pathname  
      .split('/')
      .filter(Boolean)
      .map(segment => segment.charAt(0).toUpperCase() + segment.slice(1))
      .join(' ')

    await insertProjectPage({ 
      project_id: currentProjectPage.project_id,
      path: url.pathname,
      name: newProjectPageName,
      infos: { interventions: []}
    });
	}, [currentProjectPage.project_id, insertProjectPage]);

	const updateCurrentPage = useCallback(async (url: string | URL) => {
    let parsedUrl = parseUrl(url, currentProject);

    console.log("Parsed URL:", parsedUrl);
    console.log("currentDomain:", currentProject.domain);

    if (!parsedUrl) return;
    if (parsedUrl.hostname !== currentProject.domain && parsedUrl.origin !== process.env.REACT_APP_CHECK_ACCESS_API_URL!) return;

    const newProjectPage = currentProject.pages.find(page => page.path === parsedUrl?.pathname);

    if (newProjectPage && newProjectPage.id !== currentProjectPage.id) {
      console.log("Project page found:", newProjectPage);
      setCurrentProjectPage(newProjectPage);
      return;
    } else if (newProjectPage) {
      console.log("The same page is already loaded:", newProjectPage);
      return;
    }

    insertNewProjectPage(parsedUrl);
  }, [currentProject, currentProjectPage.id, insertNewProjectPage]);

	const updateCurrentPageRef = useRef(updateCurrentPage);

	useEffect(() => {
		updateCurrentPageRef.current = updateCurrentPage;
	}, [updateCurrentPage])

	const addClickListener = useCallback((link: HTMLAnchorElement) => {
		link.addEventListener("click", (evt: MouseEvent) => {
			console.log("Link clicked: ", link, "href: ", link.href);

			evt.preventDefault();
			evt.stopPropagation();

			updateCurrentPageRef.current(link.href);

			return false;
		});

		link.setAttribute("data-ca-already-listened", "true");
	}, []);

  useEffect(() => {
    console.log(
      "Check access frame provider mounted",
      frame,
      frame?.contentWindow
    );

    if (!frame?.contentWindow) return;

    try {
      console.log("history state:", frame.contentWindow.history.state);
    } catch {
      console.error("Could not access history state");
      return;
    }

		if (!frame.contentDocument) return;

		const observer = new MutationObserver((mutations) => {
			mutations
				.flatMap((mutation) => Array.from(mutation.addedNodes))
        .filter((node): node is HTMLElement => node.nodeType === Node.ELEMENT_NODE)
        .flatMap((node) => Array.from(node.getElementsByTagName("a")))
				.filter((node) => node.getAttribute("data-ca-already-listened") !== "true")
				.forEach(addClickListener);
		});

		observer.observe(frame.contentDocument, {
			childList: true,
			subtree: true,
		});

		Array.from(
      frame.contentDocument.getElementsByTagName("a")
    )
    .filter((link) => link.getAttribute("data-ca-already-listened") !== "true")
    .forEach(addClickListener);

		return () => {
			observer.disconnect();
		};
  }, [addClickListener, frame, updateCurrentPage]);

	return currentProjectPage;
};

export default useFrameCurrentProjectPage;
